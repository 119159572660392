import React, { useState, useEffect, Profiler } from "react";
const divStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
};
const Interests = () => {
    return (
        <div id="interests" className="jumbotron jumbotron-fluid m-0" style={{backgroundColor:'#ededed'}}>
            <div className="container container-fluid">
                <div className="row">
                    <div className="col d-lg-inline align-self-center">
                        <h1 className="display-4 pb-4 text-center">Interests</h1>
                        <div className="row" style={divStyle}>
                            <div className="col text-center" >
                                <p className="lead text-center" style={{fontSize:'28px'}}>Traveling</p>
                                <img src="https://img.icons8.com/ios/100/000000/airplane-take-off.png" alt=""/>
                                <p className="lead text-center">
                                    I love traveling around the globe and experiencing the diversity of cultures.
                                    I participated in study abroad <a href="https://www.tohoku.ac.jp/en/news/university_news/from_stem_to_stern_the_2018_return_of_tssp.html" target="_blank" rel="noopener noreferrer">programs</a> at Tohuku University when I was a freshman.
                                    This experience not only taught me the various aspects of engineering but also broadened my view of Japanese culture.
                                </p>
                            </div>
                            <div className="col text-center">
                                <p className="lead text-center" style={{fontSize:'28px'}}>Challenging</p>
                                <img src="https://img.icons8.com/ios/100/000000/light-on.png" alt=""/>
                                <p className="lead text-center">
                                    Challenges always excite me as it pushes me forward to be a better person.
                                    Besides graduating in 4 years as a double-major, I am also a member of the <a href="https://honors.uw.edu" target="_blank" rel="noopener noreferrer">honors</a> program that improves my critical thinking skills.
                                    I have a <a href="https://yanyukai1998.wixsite.com/yukaiyan" target="_blank" rel="noopener noreferrer">portfolio</a> for honors where I make reflections each quarter. :D
                                </p>
                            </div>
                            <div className="col text-center">
                                <p className="lead text-center" style={{fontSize:'28px'}}>Guitar</p>
                                <img src="https://img.icons8.com/ios/100/000000/guitar.png" alt=""/>
                                <p className="lead text-center">
                                    I picked up and taught myself the classical guitar when I was in high school.
                                    Although my fingers hurt initially, it soon became my favorite way of entertainment and relaxation during schooldays.
                                    My favorite songs include Romance de Amour, kiss the rain, and miracle mountain (still learning).
                                </p>
                            </div>
                            <div className="col text-center">
                                <p className="lead text-center" style={{fontSize:'28px'}}>Movies</p>
                                <img src="https://img.icons8.com/ios/100/000000/documentary.png" alt=""/>
                                <p className="lead text-center">
                                    I am a big fan of science fiction movies, especially those about the future and outer space.
                                    For example, films like The Matrix, Interstellar, and Star Trek are my favorite ones.
                                    It gives me a fantasy of what we can achieve in the future and excites me to work in the tech field as a computer scientist.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Interests;
