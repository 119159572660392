import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SkillsTab from "./SkillsTab";
import Row from "react-bootstrap/Row";
import { Jumbotron, Container } from "react-bootstrap";
import { useScrollPosition } from "../../hooks/useScrollPosition";

const divStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
};

const Skills = () => {
    const skillsTabRef = React.useRef();
    const [isScrolled, setIsScrolled] = React.useState(false);

    useScrollPosition(
        ({ prevPos, currPos }) => {
            if (!isScrolled && currPos.y - 400 < 0) setIsScrolled(true);
        },
        [],
        skillsTabRef
    );

    let heading = "Skills";
    let hardSkills = [
        { name: "Java", value: 90 },
        { name: "Python", value: 90 },
        { name: "C/C++", value: 75 },
        { name: "MatLab", value: 70 },
        { name: "SQL", value: 60 },
        { name: "Data Structure and Algorithms", value: 85 },
        { name: "Test-Driven Development", value: 80 },
        { name: "Git/Linux environment", value: 80 },
        { name: "HTML/CSS", value: 70 },
        { name: "React", value: 50 },
    ];

    return (
        <Jumbotron ref={skillsTabRef} fluid className="bg-white m-0" id="skills" >
            <Container className="p-5 ">
                <h2 ref={skillsTabRef} className="display-4 pb-5 text-center">
                    {heading}
                </h2>
                <Tabs
                    className="skills-tabs"
                    defaultActiveKey="hard-skills"
                    id="skills-tabs"
                >
                    <Tab
                        tabClassName="skills-tab lead"
                        eventKey="hard-skills"
                        title="Technical Skills"
                    >
                        <Row className="pt-3 px-1">
                            <SkillsTab skills={hardSkills} isScrolled={isScrolled} />
                        </Row>
                    </Tab>
                    <Tab
                        tabClassName="skills-tab lead"
                        eventKey="soft-skills"
                        title="Soft Skills"
                    >
                        <div className="col d-lg-inline align-self-center">
                            <br/>
                            <div className="row" style={divStyle}>
                                <div className="col text-center">
                                    <p className="lead text-center">Collaboration</p>
                                    <img src="https://img.icons8.com/ios/75/000000/collaboration-female-male.png" alt=""/>
                                </div>
                                <div className="col text-center">
                                    <p className="lead text-center">Problem Solving</p>
                                    <img src="https://img.icons8.com/ios/75/000000/critical-thinking.png" alt=""/>
                                </div>
                                <div className="col text-center">
                                    <p className="lead text-center">Positivity</p>
                                    <img src="https://img.icons8.com/ios/75/000000/happy-cloud.png" alt=""/>
                                </div>
                            </div>
                            <br/>
                            <div className="row" style={divStyle}>
                                <div className="col text-center">
                                    <p className="lead text-center">Goal Setting</p>
                                    <img src="https://img.icons8.com/ios/75/000000/goal.png" alt=""/>
                                </div>
                                <div className="col text-center">
                                    <p className="lead text-center">Organization</p>
                                    <img src="https://img.icons8.com/ios/75/000000/to-do.png" alt=""/>
                                </div>
                                <div className="col text-center">
                                    <p className="lead text-center">Empathy</p>
                                    <img src="https://img.icons8.com/ios/75/000000/trust.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </Container>
        </Jumbotron>
    );
}

export default Skills;