import React, { Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./App.css";
import {
  showNavigationbar,
  showBlog,
} from "./editable-stuff/configurations.json";
import MainBody from "./components/home/MainBody";
import AboutMe from "./components/home/AboutMe";
import Experience from "./components/home/Experience"
import Skills from "./components/home/Skills";
import Project from "./components/home/Project";
import Interests from "./components/home/Interests";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { Blog } from "./components/blog/Blog";
import BlogPost from "./components/blog/BlogPost";
import NotFoundPage from "./components/NotFoundPage";

const Home = () => {
  return (
    <Fragment>
      {showNavigationbar && <Navbar />}
      <MainBody />
      <AboutMe />
      <Skills />
      <Project />
      <Experience />
      <Interests />
      {showBlog && <Route path="/blog" exact component={Blog} />}
      {showBlog && <Route path="/blog/:id" component={BlogPost} />}
      <Footer />
    </Fragment>
  );
};

const App = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL + "/"}>
    <Switch>
        <Route path="/" exact component={Home} />
        <Route component={NotFoundPage} />
    </Switch>
  </BrowserRouter>
);

export default App;
