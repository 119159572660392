import React, { useState, useEffect, Profiler } from "react";
import Allen from "../../editable-stuff/Allen-logo.jpg";
import Intern from "../../editable-stuff/Intern-logo.png"
import Google from "../../editable-stuff/Google-logo.png"
const divStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
};
const Experience = () => {
    return (
        <div id="experience" className="jumbotron jumbotron-fluid m-0" style={{backgroundColor:'white'}}>
            <div className="container container-fluid">
                <div className="row">
                    <div className="col d-lg-inline align-self-center">
                        <h1 className="display-4 mb-5 text-center">Experience</h1>
                        {/* <h1 className="display-4 pb-5">Experience</h1> */}
                        <div className="row" style={divStyle}>
                            <div className="col text-center" >
                                <img
                                    // className="border border-secondary rounded-circle"
                                    src={Google}
                                    alt="Google logo"
                                    width="350"
                                    height="100"
                                />
                                <br/>
                                <br/>
                                <p className="lead text-center" style={{fontSize:'22px'}}>
                                    Software Engineer
                                    <br/>
                                    February 2022 - present
                                </p>
                                {/* <h3> Google </h3> */}
                            </div>
                            <div className="col text-center">
                                <img
                                    // className="border border-secondary rounded-circle"
                                    src={Intern}
                                    alt="98point6 logo"
                                    width="350"
                                    height="100"
                                />
                                <br/>
                                <br/>
                                <p className="lead text-center" style={{fontSize:'22px'}}>
                                    Software Engineer Intern
                                    <br/>
                                    June - September 2021
                                </p>
                                {/* <h3>98point6</h3> */}
                            </div>
                            <div className="col text-center" >
                                <img
                                    // className="border border-secondary rounded-circle"
                                    src={Allen}
                                    alt="Allen school logo"
                                    width="350"
                                    height="100"
                                />
                                <br/>
                                <br/>
                                <p className="lead text-center" style={{fontSize:'22px'}}>
                                    Teaching Assistant
                                    <br/>
                                    January - June 2021
                                </p>
                                {/* <h3>Paul G. Allen School of Computer Science and Engineering </h3> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience;