import React from "react";

const NotFoundPage = () => {
    // const [backgroundType, setBackgroundType] = useState(Configs.backgroundType);

    return (
        <div className="disableOverflow">
            <div
                id="notFound"
                className="jumbotron jumbotron-fluid bg-transparent bgstyle disableoverflow text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
            >
                <div id="stars"></div>
                <div className="container container-fluid text-center ">
                    <h1 className="display-1">
                        No Page Found
                    </h1>
                    <br></br>
                    <br></br>
                    <a
                        className="btn btn-outline-light btn-lg"
                        href="/"
                        role="button"
                        aria-label="Return to home"
                    >
                        Return to Home
                    </a>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
